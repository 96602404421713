// HomeHeader.js
import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import '../styles/header.css';
import Logo from "../assets/icon/LM-Logo-light.png"

function HomeHeader() {
    const [scrolled, setScrolled] = useState(false);
    var MenuInput = document.getElementById("newPageLoaded");

    function LinkClicked() {
        MenuInput?.click();
        window.location.reload();
    }


    useEffect(() => {
        const scrollHandler = () => {
            if (window.scrollY > 200) {
                setScrolled(true);
            } else if (window.scrollY <= 10) {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', scrollHandler);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    document.addEventListener('DOMContentLoaded', function () {
        const hamburgerLink = document.querySelector('.hamburger-link'); // Ändern Sie '.hamburger-link' entsprechend Ihrer Link-Klasse oder -ID
        const targetSection = document.getElementById('goal');
        const carrierSection = document.getElementById('carrier') // Ändern Sie 'zielabschnitt' entsprechend Ihrer Ziel-ID
        const serviceSection = document.getElementById('service'); // Ändern Sie 'zielabschnitt' entsprechend Ihrer Ziel-ID
        const aboutSection = document.getElementById('aboutUS'); // Ändern Sie 'zielabschnitt' entsprechend Ihrer Ziel-ID */
        const teamSection = document.getElementById('team'); // Ändern Sie 'zielabschnitt' entsprechend Ihrer Ziel-ID */

        if (hamburgerLink && targetSection) {
            hamburgerLink.addEventListener('click', function (event) {
                event.preventDefault(); // Verhindert das Standardverhalten des Links
                targetSection.scrollIntoView({ behavior: 'smooth' }); // Scrollt zum Zielabschnitt mit sanftem Scrollen
            });
        } else if (hamburgerLink && serviceSection) {
            hamburgerLink.addEventListener('click', function (event) {
                event.preventDefault(); // Verhindert das Standardverhalten des Links
                serviceSection.scrollIntoView({ behavior: 'smooth' }); // Scrollt zum Zielabschnitt mit sanftem Scrollen
            });
        } else if (hamburgerLink && aboutSection) {
            hamburgerLink.addEventListener('click', function (event) {
                event.preventDefault(); // Verhindert das Standardverhalten des Links
                aboutSection.scrollIntoView({ behavior: 'smooth' }); // Scrollt zum Zielabschnitt mit sanftem Scrollen
            });
        } else if (hamburgerLink && teamSection) {
            hamburgerLink.addEventListener('click', function (event) {
                event.preventDefault(); // Verhindert das Standardverhalten des Links
                teamSection.scrollIntoView({ behavior: 'smooth' }); // Scrollt zum Zielabschnitt mit sanftem Scrollen
            });
        } else if (hamburgerLink && carrierSection) {
            hamburgerLink.addEventListener('click', function (event) {
                event.preventDefault(); // Verhindert das Standardverhalten des Links
                carrierSection.scrollIntoView({ behavior: 'smooth' });
            })
          }
    });

    return (
        <header className={`main-header ${scrolled ? 'scrolled' : ''}`}>
            <div className='nav-wrapper'>
                <Link to="/" className="loeffler-mueller-logo-container">
                    <img src={Logo} className='logo-img' alt="" />
                </Link>
                <nav>
                    <input type="checkbox" id='newPageLoaded' className='toggle-menu' />
                    <div className='hamburger-line'></div>
                    <div className='mobileMenu'>
                        <ul className="mobileMenuList">
                            <li className='mobile' onClick={LinkClicked}>
                                <Link className='nav-link-mobile' to="/">Startseite</Link>
                            </li>
                            <li className='mobile' onClick={LinkClicked}>
                                <HashLink className='nav-link-mobile' to="/#aboutUs">Das sind wir</HashLink>
                            </li>
                            <li className='mobile' onClick={LinkClicked}>
                                <HashLink className='nav-link-mobile' to="/#service">Leistungen</HashLink>
                            </li>
                            <li className='mobile hamburger-link' onClick={LinkClicked}>
                                <HashLink className='nav-link-mobile' to="/#goal">Unser Auftrag</HashLink>
                            </li>
                            <li className='mobile hamburger-link' onClick={LinkClicked}>
                                <HashLink className='nav-link-mobile' to="/#team">Unser Team</HashLink>
                            </li>
                            <li className='mobile hamburger-link' onClick={LinkClicked}>
                                <HashLink className='nav-link-mobile' to="/#carrier">Karriere</HashLink>
                            </li>
                            <li className='mobile hamburger-link' onClick={LinkClicked}>
                                <HashLink className='nav-link-mobile' to="/#kontakt">Kontakt</HashLink>
                            </li>
                        </ul>
                    </div>

                    <ul className="computerMenu">
                        <li>
                            <a className='nav-link' href="#aboutUs">Das sind wir</a>
                        </li>
                        <li>
                            <a className='nav-link' href="#service">Leistungen</a>
                        </li>
                        <li>
                            <a className='nav-link' href="#goal">Unser Auftrag</a>
                        </li>
                        <li>
                            <a className='nav-link' href="#team">Team</a>
                        </li>

                        <li>
                            <a className='nav-link' href="#carrier">Karriere</a>
                        </li>
                        <li>
                            <a className='nav-link' href="#kontakt">Kontakt</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
}

export default HomeHeader;
